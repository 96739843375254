<template>
  <div class="mian-container">
    <div v-if="defaultProps.isSearch" class="search-container">
      <div class="search-container-items">
        <TextInput
          v-for="(config, index) in defaultProps.searchConfigs"
          :config="config"
          :optionConfig="defaultProps.optionConfig"
          :key="index"
          :form="queryParams"
          labelPosition="left"
          v-model="queryParams[config.modelKey]"
          @dataDeal="handleSearchDataDeal"
          @returnVal="getKeyToValue($event, config)"
        ></TextInput>
      </div>

      <div class="search-container-btn">
        <el-button @click="getData" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
      </div>
    </div>
    <div class="table-container" style="width: 100%">
      <!-- 顶部action -->
      <div class="action-container">
        <div class="action-container-left">
          <slot
            name="left-l"
            :data="{ params: queryParams, selectRows: selectionRows }"
          ></slot>
          <el-button
            v-if="defaultProps.isRefresh"
            @click="getData"
            class="refresh"
            icon="el-icon-refresh"
          ></el-button>
          <slot
            name="left-r"
            :data="{ params: queryParams, selectRows: selectionRows }"
          ></slot>
        </div>
        <div class="action-container-right">
          <slot
            name="right-l"
            :data="{ params: queryParams, selectRows: selectionRows }"
          ></slot>
          <!-- 模糊搜索 -->
          <el-input
            v-if="defaultProps.isFuzzyQuery"
            v-model="queryParams[defaultProps.fuzzyQueryKey]"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-popover
            placement="bottom-start"
            style="margin-left: 10px"
            v-if="defaultProps.isColumnSet"
          >
            <el-button slot="reference" class="action-btn">
              <img
                style="width: 24px; height: 20px"
                src="@/assets/images/safeDuty/column-control.png"
                alt=""
              />
            </el-button>
            <div>
              <DraggAble v-model="defaultProps.columnProps">
                <transition-group class="draggable-column">
                  <div
                    v-for="(item, index) in defaultProps.columnProps"
                    :key="index + 'sc'"
                    class="draggable-column-item"
                  >
                    <el-checkbox checked v-model="item.isShow" fill="#0080FF">
                      {{ item.label }}
                    </el-checkbox>
                  </div>
                </transition-group>
              </DraggAble>
              <div style="display: flex">
                <el-button
                  type="primary"
                  size="mini"
                  @click.native="handleSetColumn"
                  >确认</el-button
                >
                <el-button
                  @click.native="handleResetColumnSet"
                  type="danger"
                  size="mini"
                  >恢复默认</el-button
                >
              </div>
            </div>
          </el-popover>

          <el-button
            style="margin-left: 10px"
            @click="hiddenSearch"
            v-if="defaultProps.isHiddenSet"
            class="action-btn"
            ><img
              style="width: 24.91px; height: 13.86px"
              src="@/assets/images/safeDuty/hiden-item.png"
              alt=""
          /></el-button>
          <slot
            name="right-r"
            :data="{ params: queryParams, selectRows: selectionRows }"
          ></slot>
        </div>
      </div>
      <el-table
        :data="defaultProps.isFillData ? tableData : localData"
        :row-class-name="tableRowClassName"
        :header-cell-class-name="headerRowClassName"
        :height="defaultProps.height"
        @selection-change="handleSelectionChange"
        :border="defaultProps.border"
      >
        <template slot="empty">
          <el-empty
            :description="defaultProps.empty.description"
            :image="defaultProps.empty.image"
          ></el-empty>
        </template>
        <el-table-column
          v-if="defaultProps.isSelection"
          type="selection"
          width="55"
        >
        </el-table-column>
        <template v-for="(column, index) in defaultProps.columnProps">
          <template v-if="column.isShow">
            <el-table-column
              :label="column.label"
              :width="column.width"
              :prop="column.prop"
              v-if="column.slot"
              :key="index"
            >
              <template slot-scope="scope">
                <slot
                  :name="column.prop"
                  :data="{ row: scope.row, index: scope.$index }"
                >
                </slot>
              </template>
            </el-table-column>
            <el-table-column
              :type="column.type"
              :label="column.label"
              :width="column.width"
              :prop="column.prop"
              v-else-if="column.type"
              :key="index + 'd1'"
            >
            </el-table-column>
            <el-table-column
              :type="column.type"
              :label="column.label"
              :width="column.width"
              :prop="column.prop"
              :key="index + 'd2'"
              v-else
            >
              <template slot-scope="scope">{{
                scope.row[column.prop] ||
                column.defaultValue ||
                defaultProps.defaultValue
              }}</template>
            </el-table-column>
          </template>
        </template>
      </el-table>
      <!-- 分页器 -->
      <div v-if="defaultProps.isPagination" class="pagination">
        <div>共计{{ queryParams.total }}条记录</div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryParams.currentSize"
          layout="sizes, prev, pager, next, jumper"
          :total="queryParams.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { updateTitleDiy } from "@/api/getData.js";
import { mapState, mapMutations } from "vuex";
import DraggAble from "vuedraggable";
import TextInput from "../components/TextInput.vue";
export default {
  name: "SimpleTable",
  components: { TextInput, DraggAble },
  props: {
    // 废弃（会出问题）
    extraParams: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableProps: {
      type: Object,
      default: () => {
        return {};
      },
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    queryFun: {
      type: Function,
      default: async () => {
        return [];
      },
    },
  },
  computed: {
    ...mapState(["diyRespList"]),
  },
  watch: {
    tableProps: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.defaultProps = _.assign(this.defaultProps, newValue);
      },
    },
    extraParams(n) {
      console.log(n, 111);
    },
  },
  created() {
    this.init();
    this.getData();
  },
  data() {
    return {
      queryParams: {
        currentPage: 1,
        currentSize: 10,
        total: 0,
      },
      selectionRows: [],
      defaultProps: {
        empty: {
          description: "列表暂无数据",
          image: require("@/assets/images/RiskControl/risk-empty.png"),
        },
        //columnProps里面有，用里面的
        defaultValue: "---",
        fuzzyQueryKey: "fuzzyQuery",
        currentPageKey: "currentPage",
        currentSizeKey: "currentSize",
        totalKey: "total",
        isFillData: false,
        isSelection: true,
        isColumnSet: true,
        isHiddenSet: true,
        isSearch: true,
        isPagination: true,
        isRefresh: true,
        isFuzzyQuery: true,
        border: false,
        height: 380,
        searchConfigs: [],
        columnProps: [],
        optionConfig: {
          value: "dictKey",
          label: "dictValue",
        },
      },
      localData: [],
    };
  },
  methods: {
    ...mapMutations(["SetDiyRespList"]),
    init() {
      //this.handleColumnsSetting(true);
    },
    handleSizeChange(i) {
      this.queryParams.currentSize = i;
      this.getData();
    },
    handleCurrentChange(i) {
      this.queryParams.currentPage = i;
      this.getData();
    },
    getData() {
      if (this.isFillData) return;
      this.queryParams[this.defaultProps.currentPageKey] =
        this.queryParams.currentPage;
      this.queryParams[this.defaultProps.currentSizeKey] =
        this.queryParams.currentSize;
      //console.log(this.queryParams, this.extraParams);
      this.queryFun({ ...this.queryParams, ...this.extraParams }).then(
        (res) => {
          if (res) {
            this.localData = res.localData;
            this.queryParams.total = res.total || 0;
          } else {
            this.localData = [];
            this.queryParams.total = 0;
            this.queryParams.currentPage = 1;
          }

          this.$forceUpdate();
        }
      );
    },
    getParams() {
      return this.queryParams;
    },
    setOption(data, key) {
      this.defaultProps.searchConfigs.find((item) => {
        if (item.modelKey === key) {
          item.option = data;
        }
      });
    },
    handleColumnsSetting(init = false) {
      // init false 更新
      //以路由名称作为表名
      let diyobj = this.diyRespList.obj[this.$route.name];
      if (init) {
        if (diyobj) {
          this.defaultProps.columnProps = diyobj.value;
        }
      } else {
        let divid = diyobj
          ? this.diyRespList.list[this.diyRespList.obj[this.$route.name].index]
              .id
          : "";
        updateTitleDiy({
          id: divid,
          tableKey: this.$route.name,
          tableValue: JSON.stringify(this.defaultProps.columnProps),
        }).then((res) => {
          console.log(res);
        });
      }
    },
    hiddenSearch() {
      this.defaultProps.isSearch = !this.defaultProps.isSearch;
    },
    handleResetColumnSet() {
      this.defaultProps.columnProps.forEach((item) => {
        item.isShow = true;
      });
    },
    handleSetColumn() {
      this.handleColumnsSetting();
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "zdy-row";
      }
    },
    headerRowClassName() {
      return "zdy-header-row";
    },
    handleSelectionChange(rows) {
      this.selectionRows = rows;
      this.$emit("selection-change", rows);
    },
    handleSearchDataDeal(val) {
      this.queryParams = { ...this.queryParams, ...val };
    },
    reset() {
      let keys = Object.keys(this.queryParams);
      keys.forEach((key) => {
        if (this.queryParams[key] instanceof Array) {
          this.queryParams[key] = [];
        } else {
          this.queryParams[key] = "";
        }
      });
      this.queryParams = {
        ...this.queryParams,
        ...{
          currentPage: 1,
          currentSize: 10,
          total: 0,
        },
      };
      this.getData();
    },
    getKeyToValue(res, res2) {
      if (res.comType == "distpicker") {
        Object.keys(res.data).forEach((key) => {
          this.queryParams[key] = res.data[key];
        });
      } else if (res.comType == "select" && res.type) {
        // TODO 需要优化 动态key
        this.queryParams[res.type] = res.data.label || res.data.dictValue;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table {
  border-radius: 10px 10px 10px 10px;
}
.mian-container {
  display: flex;
  flex-direction: column;
}
.search-container {
  background: #ffffff;
  padding: 16px 12px;
  border-radius: 8px;
  margin-bottom: 10px;
  display: flex;
  &-items {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(auto-fill, 360px);
    place-items: center;
  }
  &-btn {
    display: flex;
    align-items: flex-end;
    align-self: end;
  }
}
.table-container {
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  .action-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;
    &-left {
      display: flex;
    }
    &-right {
      display: flex;
      justify-content: space-between;
    }
    .action-btn {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #4278c9;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .refresh {
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #4278c9;
      color: #4278c9;
      font-size: 20px;
      padding: 6px !important;
    }
  }
  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }
}
.draggable-column {
  display: flex;
  flex-direction: column;
  &-item {
  }
}
</style>
<style>
.el-table .zdy-row {
  background: rgba(235, 243, 255, 0.5);
}
.zdy-header-row {
  /* background-color: #f2f7fd !important; */
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}
</style>
