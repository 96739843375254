// 数值添加千分位分隔符
export function addThousandSeparator(num, decimalPlaces = 0) {
  // 如果传入的是非数字类型，尝试转换为数字；否则返回空字符串
  const number = typeof num === "number" ? num : parseFloat(num);
  if (isNaN(number)) return "";

  // 使用 toLocaleString 方法添加千位分隔符，指定本地化为 "en-US" 以确保使用逗号作为分隔符
  let formattedNumber = number.toLocaleString("en-US");

  // 如果需要保留小数位，使用正则表达式提取整数部分和小数部分
  if (decimalPlaces > 0) {
    const parts = formattedNumber.split(".");
    formattedNumber = `${parts[0]}.${
      parts[1]?.padEnd(decimalPlaces, "0") ?? ""
    }`;
  }

  return formattedNumber;
}
