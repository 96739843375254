<template>
  <div class="main-container">
    <div class="search-box">
      <div class="search-box-params">
        <TextInput
          style="margin-left: 50px"
          @returnVal="getKeyToValue"
          v-model="params.companyKey"
          :optionConfig="{
            value: 'dictKey',
            label: 'dictValue',
          }"
          :config="{
            type: 'select',
            option: dictList.insuranceCompany,
            modelTextKey: 'companyName',
            label: '保险公司',
          }"
          labelPosition="left"
        ></TextInput>
        <TextInput
          style="margin-left: 50px"
          v-model="params.insuranceDate"
          @returnVal="getKeyToValue"
          :form="params"
          :config="{
            type: 'daterange',
            daterangeKeys: ['insuranceStartDateStart', 'insuranceStartDateEnd'],
            modelTextKey: 'insuranceDate',
            daterangeFormats: ['YYYY-MM-DD', 'YYYY-MM-DD'],
            label: '起保日期',
          }"
          labelPosition="left"
        >
        </TextInput>
      </div>
      <div class="search-box-btns">
        <el-button type="primary" @click="getData">查询</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <div style="display: flex">
      <CardBox
        style="flex: 16; margin-right: 10px"
        :title="params.flag == 1 ? '保费占比' : '保单占比'"
      >
        <div slot="action">
          <el-button
            :class="['change-btn', params.flag == 1 ? 'change-btn-active' : '']"
            @click="changeFlag(1)"
            >保费</el-button
          >
          <el-button
            :class="['change-btn', params.flag == 2 ? 'change-btn-active' : '']"
            @click="changeFlag(2)"
            >保单</el-button
          >
        </div>
        <div class="chart-box">
          <Chart
            style="height: 100%; width: 100%"
            :option="pieOption"
          ></Chart></div
      ></CardBox>
      <CardBox style="flex: 16" title="服务分布"
        ><div class="chart-box">
          <Chart
            style="height: 100%; width: 100%"
            :option="barOption"
          ></Chart></div
      ></CardBox>
    </div>
    <CardBox title="保单清单">
      <SimpleTable
        ref="policyTable"
        :tableProps="policyTableProps"
        :queryFun="policyQueryFun"
      >
        <template slot="policyNumber" slot-scope="{ data }">
          <el-button @click="toPolicyDetail(false, data.row)" type="text">{{
            data.row.policyNumber
          }}</el-button>
        </template>
      </SimpleTable>
      <div @click="toDetail('safeDutyManagement')" class="detail" slot="action">
        查看详细 <i class="el-icon-arrow-right"></i>
      </div>
    </CardBox>
    <CardBox title="案件列表">
      <SimpleTable
        ref="caseTable"
        :tableProps="caseTableProps"
        :queryFun="claimFunQueryFun"
      >
      </SimpleTable>
      <div
        @click="toDetail('safeDutyClaimManagement')"
        class="detail"
        slot="action"
      >
        查看详细 <i class="el-icon-arrow-right"></i>
      </div>
    </CardBox>
  </div>
</template>

<script>
import SimpleTable from "../components/SimpleTable";
import CardBox from "../components/CardBox";
import Chart from "@/components/Chart/Charts";
import { setComPieChartOption, setComBarChartOption } from "../js/chart.js";
import {
  getInsuranceCompanyDataBoardPolicyList,
  getInsuranceCompanyDataBoardClaimList,
  getInsuranceCompanyDataBoard,
} from "@/api/safeDuty.js";
import { dictionaryBatch } from "@/api/policy";
import TextInput from "../components/TextInput.vue";
export default {
  components: { SimpleTable, CardBox, Chart, TextInput },
  mounted() {
    this.setChartOption();
  },
  data() {
    return {
      initData: {},
      dictList: {},
      params: {
        companyKey: "",
        companyName: "",
        flag: 1,
        insuranceDate: [],
        insuranceStartDateEnd: "",
        insuranceStartDateStart: "",
      },
      pieOption: {},
      barOption: {},
      caseTableProps: {
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isRefresh: false,
        isFuzzyQuery: false,
        border: true,
        currentPageKey: "current",
        currentSizeKey: "size",
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            slot: false,
            isShow: true,
          },
          {
            prop: "accidentType",
            label: "事故类型",
            width: "",
            type: "",
            isShow: true,
          },

          {
            prop: "dangerDate",
            label: "出险时间",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "causeOfAccident",
            label: "事故原因",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "responsibleParty",
            label: "责任方",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "compensationTotalAmount",
            label: "损失金额",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "avoidanceMeasures",
            label: "规避措施",
            width: "",
            type: "",
            isShow: true,
          },
        ],
      },
      policyTableProps: {
        isSelection: false,
        isColumnSet: false,
        isHiddenSet: false,
        isSearch: false,
        isRefresh: false,
        isFuzzyQuery: false,
        border: true,
        currentPageKey: "current",
        currentSizeKey: "size",
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
            isShow: true,
          },
          {
            prop: "policyNumber",
            label: "保单号",
            width: "",
            type: "",
            isShow: true,
            slot: true,
          },

          {
            prop: "insuredName",
            label: "被保险人",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuranceCompanyName",
            label: "保险公司",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "planName",
            label: "方案名称",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "projectName",
            label: "项目名称",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "salesmanName",
            label: "业务员",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuranceStartDate",
            label: "起保日期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "insuranceEndDate",
            label: "保险到期",
            width: "",
            type: "",
            isShow: true,
          },
          {
            prop: "totalPremium",
            label: "总保费(元)",
            width: "",
            type: "",
            isShow: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      let dictKeys = ["insuranceCompany"];
      dictionaryBatch({
        codes: dictKeys.join(","),
      }).then((res) => {
        if (res && res.data) {
          dictKeys.forEach((key) => {
            this.dictList[key] = res.data[key] || [];
          });
        }
      });
      getInsuranceCompanyDataBoard(this.params).then((res) => {
        this.initData = res.data;
        this.setChartOption();
      });
    },
    setChartOption() {
      this.pieOption = setComPieChartOption(
        this.initData.thanDataList || [],
        this.params.flag,
        this.initData.totalNumber
      );
      this.barOption = setComBarChartOption(
        this.initData.serviceDistributionList
      );
    },
    async policyQueryFun(queryParams) {
      let res = await getInsuranceCompanyDataBoardPolicyList({
        ...queryParams,
        ...this.params,
      });
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
    changeFlag(e = 1) {
      this.params.flag = e;
      this.init();
    },
    async claimFunQueryFun(queryParams) {
      let res = await getInsuranceCompanyDataBoardClaimList({
        ...queryParams,
        ...this.params,
      });
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
    reset() {
      this.params = {
        companyKey: "",
        companyName: "",
        flag: 1,
        insuranceDate: [],
        insuranceStartDateEnd: "",
        insuranceStartDateStart: "",
      };
      this.getData();
    },
    getData() {
      this.init();
      this.$refs.policyTable.getData();
      this.$refs.caseTable.getData();
    },
    getKeyToValue(res, res2) {
      if (res.comType == "daterange") {
        this.params = { ...this.params, ...res.data.dates };
      } else if (res.comType == "select") {
        this.params[res.type] = res.data.dictValue;
        this.params.insuranceCompany = res.data.dictValue;
      }
    },
    toDetail(pathName) {
      if (!pathName) return;
      this.$router.push({
        name: pathName,
        // params: {
        //   ...this.params,
        // },
      });
    },
    toPolicyDetail(edit = false, item = {}) {
      this.$router.push({
        name: "safeDutyManagementDetail",
        params: {
          id: item?.id,
          isEdit: edit,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .action-container {
  padding: 0 !important;
}
.chart-box {
  height: 340px;
}
.search-box {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 10px;
  padding: 10px 0 16px 0;
  align-items: flex-end;
  &-params {
    display: flex;
    align-items: center;
  }
  &-btns {
    margin-right: 12px;
  }
}
.change-btn {
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #4278c9;
}
.change-btn-active {
  background: #4278c9;
  color: #ffffff;
}
.detail {
  font-weight: 400;
  font-size: 14px;
  color: #3470ff;
  cursor: pointer;
}
</style>
