<template>
  <div class="chart"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
    pieHight: {
      type: Boolean,
      default: false,
    },
    isLegendClick: { //是否监听legend点击事件
      type: Boolean,
      default: false,
    },
    indexList: {
      type: Array,
    },
    chartMap: {
      type: String,
      default: ""
    },
    notMerge: { //是否监听legend点击事件
      type: Boolean,
      default: true,
    },
  },

  data() {
    this.intervalId = null;
    return {
      chart: null,
      highlightIndex: 1,
    };
  },

  watch: {
    option: {
      async handler(val) {
        if (!val) {
          this.clearChart();
          return;
        }
        await this.$nextTick();
        const isRenderFail =
          !this.$el ||
          (this.$el &&
            this.$el.firstChild &&
            this.$el.firstChild.style.width === "0px");
        await this.$nextTick();
        if (isRenderFail || !this.chart) {
          this.clearChart();
          if (this.$el) {
            this.$el.removeAttribute("_echarts_instance_");
            this.$el.innerHTML = "";
          }
          this.chart = echarts.init(this.$el);
        }
        this.chart.clear();
        this.chart.setOption(val, this.notMerge);
        this.chart.off('click'); // 先移除点击事件
        this.chart.on('click', (param) => { // 添加地图点击事件
          //console.log(param);
          this.$emit('on-click', param);
        });
        this.$emit('onReady', this.chart)
        this.handleResizeChart()
        if (this.isLegendClick) {
          this.chart.on('legendselectchanged', (param) => { // 添加地图点击事件
            //console.log(param);
            this.$emit('on-click', param);
          });
        }
        if (this.pieHight) {
          clearInterval(this.intervalId);
          let indexList = val.series[0].data.map((p, index) => index + 1);
          this.setHighlight(this.highlightIndex, 1);

          this.intervalId = setInterval(() => {
            const index1 = this.highlightIndex % indexList.length;
            const index2 = (this.highlightIndex - 1) % indexList.length;

            this.setHighlight(index1, index2);
            this.highlightIndex++;
          }, 4000);

          if (indexList.length === 1) {
            clearInterval(this.intervalId);
            this.highlightIndex = 0;
          }
        }
      },
      deep: true
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResizeChart);
  },

  beforeDestroy() {
    this.clearChart();
    window.addEventListener("resize", this.handleResizeChart);
  },

  methods: {
    clearChart() {
      if (!this.chart) return;
      this.chart.dispose();
      this.chart = null;
    },
    handleResizeChart() {
      if (this.chart && !this.chartMap) {
        // echarts5.5版本加载动画bug 需要加入动画属性animation
        this.chart.resize({
        animation: {
          duration: 1000,
          easing: 'cubicInOut',
          // delay: 500,
        },
      });
      }
      // this.chart && this.chart.resize();
    },
    setHighlight(index, index2) {
      this.chart.dispatchAction({
        type: "downplay",
        seriesIndex: 0,
        dataIndex: index2,
      });

      this.chart.dispatchAction({
        type: "highlight",
        seriesIndex: 0,
        dataIndex: index,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  width: 100%;
  position: relative;
  & > div:first-child {
    width: 100% !important;
    height: 100% !important;
  }
}
</style>