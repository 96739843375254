import { addThousandSeparator } from "@/utils/dealNumber";
// 服务图表数据生成
export function setSerCardChartOption(data = [], xtypename = "") {
  let barData = [];
  let timeData = [];
  let returnOptions = {};
  let scatterList = data || [];
  if (scatterList.length) {
    for (let i = 0; i < scatterList.length; i++) {
      timeData.push(scatterList[i].date);
      barData.push({
        value: scatterList[i].count || 0,
        itemStyle: {
          color: "#4A8AFD",
        },
      });
    }
  } else {
    for (let i = 0; i < 12; i++) {
      timeData.push(i);
      barData.push({
        value: 0,
        itemStyle: {
          color: "#4A8AFD",
        },
      });
    }
  }
  returnOptions = {
    tooltip: {
      //   trigger: "item", //默认效果
      //柱状图加阴影
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },
    grid: {
      top: "10%",
      left: "2%",
      right: "6%",
      bottom: "6%",
      containLabel: true,
    },
    color: ["#FF7474"],
    xAxis: {
      name: xtypename,
      type: "category",
      data: timeData,
      axisLabel: {
        textStyle: {
          color: "rgba(0, 0, 0, 1)",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#cccccc",
        },
      },
    },
    yAxis: {
      name: "服务次数",
      type: "value",
      minInterval: 1,
      splitLine: {
        show: true,
        lineStyle: {
          color: "#f5f5f5",
        },
      },
    },
    series: [
      {
        name: "次数",
        data: barData,
        barWidth: 15,
        type: "bar",
      },
    ],
  };
  return returnOptions;
}
//保险公司饼图数据设置
export function setComPieChartOption(data = [], ct = 1, total = 0) {
  let ctypes = {
    1: {
      mtips: "保费总金额(元)",
      ni: "元",
    },
    2: { mtips: "保单总数", ni: "张" },
  };
  let returnOptions = {};
  let dataList = [];
  let colorList = [
    "#DDE0E2",
    "#B6CAF7",
    "#1159FF",
    "#64B2FF",
    "#66D3FB",
    "#B3E8FB",
    "#DDE0E2",
  ];
  if (!data?.length) {
    dataList = [
      {
        typeName: "",
        name: "",
        value: 0,
        rate: "",
      },
    ];
  } else {
    dataList = data?.map((item, index) => {
      return {
        typeName: item.name,
        name: item.name,
        value: item.number || 0,
        rate: item.than || 0,
      };
    });
  }

  let center = ["25%", "50%"];

  returnOptions = {
    color: colorList,
    legend: {
      type: "scroll",
      y: "center",
      left: "50%",
      orient: "vertical", //改变排列方式
      icon: "circle", //改变legend小图标形状
      itemGap: 16, // 设置legend的间距
      itemWidth: 16, // 设置宽度
      itemHeight: 16, // 设置高度
      formatter: function (name) {
        for (let i = 0; i < dataList.length; i++) {
          if (dataList[i].name == name) {
            return `{a|${dataList[i].typeName}}|{b|${
              dataList[i].rate
            }%}{c|${addThousandSeparator(dataList[i].value || 0)}${
              ctypes[ct].ni
            }}`;
          }
        }
      },
      textStyle: {
        rich: (function () {
          return {
            a: {
              fontSize: 14,
              fontWeight: "400",
              color: "#333333",
              padding: [0, 8, 0, 8],
            },
            c: {
              fontSize: 14,
              fontWeight: "bold",
              color: "#333333",
              padding: [0, 0, 0, 10],
            },
            b: {
              fontSize: 14,
              fontWeight: "bold",
              color: "#999999",
              padding: [0, 0, 0, 8],
            },
          };
        })(),
      },
    },
    tooltip: {
      trigger: "item",
      formatter: function (params, ticket, callback) {
        return `<div style="font-size: 12px;font-weight: 400">
                    <div style="display:flex;border-bottom: 1px solid rgba(255,255,255,0.1);">
                      <div>${params.data.rate || 0}%</div>
                      <div style='margin:0 0 0 42px'>${addThousandSeparator(
                        params.data.value || 0
                      )}${ctypes[ct].ni}</div>
                    </div>
                    <div style="display:flex;align-items: center;">
                      <div style="height: 14px;width: 14px;border-radius: 50%;background-color:${
                        params.color
                      }"></div>
                      <div style='margin:0 0 0 2px'>${
                        params.data.typeName
                      }</div>
                    </div>
                  </div>`;
      },
      backgroundColor: "#292C35",
      textStyle: {
        color: "#fff",
      },
      padding: [10, 8],
      axisPointer: {
        type: "shadow",
        shadowStyle: {
          color: "#fff",
        },
      },
    },
    series: [
      {
        name: `${ctypes[ct].mtips}`,
        type: "pie",
        center: center,
        radius: ["90%", "65%"],
        itemStyle: {
          borderWidth: 5, //描边线宽
          borderColor: "#fff",
          borderRadius: 8,
        },
        label: {
          show: true,
          position: "center",
          formatter: [
            `{a|${ctypes[ct].mtips}}`,
            `{b|${addThousandSeparator(total || 0)}}`,
          ].join("\n"),
          shadowColor: "#e3e3e3",
          shadowBlur: 10,
          borderRadius: 200,
          rich: {
            a: {
              fontWeight: "400",
              padding: [0, 0, 0, 0],
              color: "#333333",
              fontSize: 14,
            },
            b: {
              fontWeight: "bold",
              padding: [5, 0, 0, 0],
              color: "#333333",
              fontSize: 24,
            },
          },
        },
        emphasis: {
          scale: 1.1,
        },
        selectedMode: "series",
        selectedOffset: 100,
        select: {},
        data: dataList,
      },
    ],
  };
  return returnOptions;
}
//保险公司横向柱状图数据设置
export function setComBarChartOption(data = []) {
  let returnOptions = {};
  let seriesData = [[], [], []];
  let dataArr = [];
  let yseries = [];
  let barKeys = [
    { key: "amount", name: "金额", color: "#4F83FD", unit: "元" },
    { key: "number", name: "次数", color: "#63DAAB", unit: "次" },
    { key: "than", name: "占比", color: "#B9CEFD", unit: "%", isRate: true },
  ];
  let max = 0;
  data.map((item, index) => {
    dataArr.push(item.name);
    barKeys.forEach((keyobj, ki) => {
      item[keyobj.key] > max && (max = item[keyobj.key]);
      seriesData[ki].push({
        ...keyobj,
        origValue: item[keyobj.key],
        value: item[keyobj.key],
        name: item.name,
        title: keyobj.name,
      });
    });
  });
  seriesData.forEach((data, index) => {
    yseries.push({
      type: "bar",
      barWidth: 13,
      barGap: 0.5,
      name: barKeys[index].name,
      label: {
        show: true,
        position: "right",
        formatter: function (d) {
          return d.data.origValue + d.data.unit;
        },
        textStyle: {
          color: "#333333",
          fontSize: 16,
          padding: [0, 10],
          rich: {
            a: {
              color: "#333333",
              fontSize: 36,
            },
          },
        },
      },
      legendHoverLink: false,
      silent: true,
      itemStyle: {
        color: barKeys[index].color, //底色
      },
      data: data.map((item) => {
        let res = item;
        if (res.isRate) {
          res.value = (res.value / 100) * max;
        }
        return res;
      }),
    });
  });
  returnOptions = {
    backgroundColor: "#FFFFFF",
    grid: {
      left: "12%",
      right: "20%",
      top: "12%",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          show: false,
        },
      },
      formatter: function (d) {
        let html = document.createElement("div");
        let div = ``;
        d.forEach((item, index) => {
          div += `<div style="display:flex;align-items: center;">
                      <div style="height: 14px;width: 14px;background-color:${
                        item.color
                      }"></div>
                      <div style='margin:0 0 0 2px;display:flex;justify-content: space-between;'>
                      <div>${item.data.title}(${item.data.unit})</div>
                      <div style="margin:0 0 0 10px;font-weight:bold;">${addThousandSeparator(
                        item.data.origValue || 0
                      )}${item.data.unit}</div>
                      </div>
                  </div>`;
        });
        html.style.display = "flex";
        html.style.flexDirection = "column";
        html.innerHTML = div;
        return html;
      },
    },
    legend: {
      show: true,
      // itemWidth:52,
      // itemHeight:30,
      // itemGap:50,
      orient: "vertical",
      top: "12%",
      right: "4%",
      textStyle: {
        color: "#333333",
        fontSize: 16,
      },
    },
    xAxis: {
      type: "value",
      //show: false,
      splitLine: {
        show: true,
        lineStyle: {
          color: "#F2F7FD",
        },
      },
      axisLine: {
        show: false,
      },
      axisLabel: {
        show: false,
        textStyle: {
          color: "#333333",
          fontSize: 16,
        },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: [
      {
        type: "category",
        inverse: true,
        data: dataArr,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          // "margin": 10,
          textStyle: {
            color: "#333333",
            fontSize: 16,
          },
          width: 20,
          formatter: function (value) {
            let len = value.length;
            let lnum = len / 5;
            let str = "";
            for (let i = 0; i < lnum; i++) {
              str += value.substr(i * 5, 5) + "\n";
            }
            return str;
          },
        },
      },
    ],
    series: yseries,
  };
  return returnOptions;
}
